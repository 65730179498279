<template>
  <div class="md-layout">
    <div class="md-layout-item textarea-layout">
      <h5>Diagnosis</h5>
      <textarea
        disabled
        label="Diagonis"
        v-model="currentlyselectedfinding.value.diagnosys"
        :isdisabled="true"
      ></textarea>
      <h5>Recommendation</h5>
      <textarea
        disabled
        label="recommendation"
        v-model="currentlyselectedfinding.value.recommendation"
        :isdisabled="true"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["currentlyselectedfinding"],
};
</script>
<style scoped>
h5 {
  font-weight: bold;
}
</style>
