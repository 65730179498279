import ResetPasswordService from "../../../services/module-services/user/ResetPasswordService";
import NavigationService from "../../../services/common-services/NavigationService";
const VoluntaryResetPasswordHandler = {
  mixins: [ResetPasswordService, NavigationService],
  methods: {
    UserVoluntaryResetPassword() {
      this.VoluntaryPasswordChange();
    },
    navigateToSettingsPage() {
      this.navigateTo("settings");
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      passwordReset: this.passwordReset,
      passwordResetConfirm1: this.UserVoluntaryResetPassword,
      cancelReset: this.navigateToSettingsPage,
    };
  },
};

export default VoluntaryResetPasswordHandler;

export const withVoluntaryResetPasswordHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<VoluntaryResetPasswordHandler><WrappedComponent v-bind="$props"></WrappedComponent></VoluntaryResetPasswordHandler>`,
    components: { VoluntaryResetPasswordHandler, WrappedComponent },
  };
};
