<template>
  <ValidationListener
    @getIsValid="(isValid) => (passwordReset.isFormValid = isValid)"
  >
    <md-card>
      <md-card-header class="profile-header">
        <div class="card-icon">
          <i class="fas far fa-key" />
        </div>
        <h4 class="title">PASSWORD RESET</h4>
      </md-card-header>

      <md-card-content>
        <loading :active="app.showLoader.value" :can-cancel="true"></loading>

        <div class="md-layout">
          <div
            class="md-layout-item md-size-100"
            v-if="app.userProfile.isTempPassword"
          >
            <p style="color: red">
              You are using a temporary password. Please change your password to
              continue.
            </p>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 p-4">
            <div slot="inputs">
              <ValidationHandler
                :name="passwordReset.postData.email"
                rules="required"
                v-slot="{ error }"
              >
                <text-input
                  type="email"
                  label="Email"
                  placeholder="Email"
                  icon="mail"
                  :required="true"
                  v-model="passwordReset.postData.email"
                  :error="error"
                  :isdisabled="true"
                  icons="mail_outline"
                  @submit="passwordResetConfirm1"
                />
              </ValidationHandler>
              <ValidationHandler
                :name="passwordReset.postData.currentPassword"
                rules="required"
                v-slot="{ error }"
              >
                <text-input
                  type="password"
                  label="Current Password"
                  placeholder="Current Password"
                  icon="lock_outline"
                  :required="true"
                  v-model="passwordReset.postData.currentPassword"
                  :error="error"
                  icons="password"
                  @submit="passwordResetConfirm1"
                />
              </ValidationHandler>
              <ValidationHandler
                :name="passwordReset.postData.password"
                rules="required"
                v-slot="{ error }"
              >
                <text-input
                  type="password"
                  label="New Password"
                  placeholder="New Password"
                  icon="lock_outline"
                  :required="true"
                  v-model="passwordReset.postData.password"
                  :error="error"
                  icons="password"
                  @submit="passwordResetConfirm1"
                />
              </ValidationHandler>
              <ValidationHandler
                :name="passwordReset.postData.confirmPassword"
                rules="required"
                v-slot="{ error }"
              >
                <text-input
                  type="password"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  icon="lock_outline"
                  :required="true"
                  v-model="passwordReset.postData.confirmPassword"
                  :error="error"
                  icons="password"
                  @submit="passwordResetConfirm1"
                />
              </ValidationHandler>
            </div>
            <div>
              <div class="button-container">
                <md-button
                  slot="footer"
                  @click="passwordResetConfirm1"
                  class="md-primary"
                >
                  Reset
                </md-button>
                <md-button slot="footer" @click="cancelReset">Cancel</md-button>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 p-4">
            <h3 class="md-subheading">Note: Password Criteria</h3>
            <ul>
              <li>Password must be at least 10 characters long</li>
              <li>Password must be at most 50 characters long</li>
              <li>
                Password must have at least one alphabet, one numerical, and one
                special character (@,$,!,%,*,#,?,&,_,-).
              </li>
              <li>Username and Password must not be the same</li>
            </ul>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </ValidationListener>
</template>

<script>
import TextInput from "../../components/controls/TextInput.vue";

export default {
  components: { TextInput },
  created() {
    this.passwordReset.postData.email = this.app.userProfile.email;
  },

  inject: ["passwordReset", "passwordResetConfirm1", "cancelReset", "app"],
};
</script>
