<template>
  <fragment>
    <div class="card-sub">
      <div class="card-sub-header">
        <h4 class="title">{{ title }}</h4>
        <div class="container-lgrow-smcol">
          <HasPermission :permission="Permissions.IsSuperAdmin">
            <div class="outline-blue-button">
              <md-button @click="handleManageFindingsClick('Common')"
                ><i class="fa fa-plus-circle"></i> Common Findings</md-button
              >
            </div>
          </HasPermission>
          <div class="outline-blue-button">
            <div>
              <!-- <div v-if="findingsMaster.masterName == 'LensStatus'"></div> -->
              <div>
                <md-button @click="handleManageFindingsClick('Additional')"
                  ><i class="fa fa-plus-circle"></i> Additional
                  Findings</md-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-sub-content">
        <div class="md-layout">
          <div class="md-layout-item md-size-30 md-small-size-100">
            <CommonfindingsSettings />
          </div>
          <div class="md-layout-item md-size-30 md-small-size-100">
            <!-- <div v-if="findingsMaster.masterName == 'LensStatus'"></div> -->
            <div>
              <div>
                <AdditionalFindings />
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-40 md-small-size-100">
            <Reccomdation />
          </div>
        </div>
      </div>
      <md-dialog :md-active.sync="findingsMaster.showManageFindings">
        <ManageFindingsHandler
          :type="findingsMaster.manageFindingsType"
          :findingsId="findingsMaster.manageFindingsId"
        >
          <ManageFindings></ManageFindings>
        </ManageFindingsHandler>
      </md-dialog>
    </div>
    <loading
      :active="findingsMaster.isFindingsDeleting"
      :can-cancel="true"
    ></loading>
  </fragment>
</template>
<script>
import CommonfindingsSettings from "./findingspreferencesettings/CommanfindingsSettings.vue";
import AdditionalFindings from "./././findingspreferencesettings/AdditionalFindingsSettings.vue";
import Reccomdation from "./findingspreferencesettings/Reccomdationanddiagonis.vue";
import ManageFindingsHandler from "../../../handlers/module-handlers/findings/ManageFindingsHandler";
import ManageFindings from "../../../components/Globechek/Findings/findingspreferencesettings/ManagefindingsSettings/ManageFindingsSettings.vue";

export default {
  inject: ["findingsMaster", "handleManageFindingsClick", "Permissions"],

  components: {
    AdditionalFindings,
    ManageFindings,
    ManageFindingsHandler,
    CommonfindingsSettings,
    Reccomdation,
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    if (this.findingsMaster.masterName == "ExternalPhoto") {
      this.title = "EXTERNAL PHOTO";
    } else if (this.findingsMaster.masterName == "CornealTopography") {
      this.title = "CORNEAL TOPOGRAPHY";
    } else if (this.findingsMaster.masterName == "AnteriorSegmentOCT") {
      this.title = "ANTERIOR SEGMENT OCT";
    } else if (this.findingsMaster.masterName == "FundusPhoto") {
      this.title = "FUNDUS PHOTO";
    } else if (this.findingsMaster.masterName == "MacularOCT") {
      this.title = "MACULAR OCT";
    } else if (this.findingsMaster.masterName == "LensStatus") {
      this.title = "LENS STATUS";
    } else {
      this.title = "OPTIC NERVE OCT";
    }
  },
};
</script>
<style scoped>
h4 {
  font-weight: bold;
}
</style>
