<template>
  <div>
    <div class="md-layout-item">
      <h3 class="md-title m-0">MY SETTINGS</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <div class="layout-left-menu">
          <ul>
            <li :class="{ active: type === 'pwd' }" @click="pwd">
              Password Setting
            </li>
            <HasPermission
              :permission="Permissions.ScreeningevaluationSettings"
            >
              <li :class="{ active: type === 'find' }" @click="findings">
                Screening Evaluation
              </li>
            </HasPermission>
            <li
              :class="{ active: type === 'noti' }"
              @click="notificationpreference"
            >
              Notifications
            </li>
          </ul>
        </div>
      </div>
      <div class="md-layout-item md-size-75 md-small-size-100">
        <div v-if="type === 'pwd'"><password-reset /></div>
        <div v-else-if="type === 'find'"><ScreeningFindingsPreferences /></div>
        <div v-else-if="type === 'noti'"><notification-preference /></div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPreference from "./NotificationPreference.vue";
import { withMySettingsHandler } from "../../handlers/module-handlers/profile/MySettingsHandler";
import PasswordReset from "./PasswordReset.vue";
import { withVoluntaryResetPasswordHandler } from "../../handlers/module-handlers/user/VoluntaryResetPasswordHandler";
import ScreeningFindingsPreferences from "./ScreeningFindingsPreferences.vue";

export default {
  components: {
    NotificationPreference: withMySettingsHandler(NotificationPreference),
    PasswordReset: withVoluntaryResetPasswordHandler(PasswordReset),
    ScreeningFindingsPreferences: ScreeningFindingsPreferences,
  },
  inject: ["Permissions", "app"],
  data() {
    return {
      type: "pwd",
    };
  },

  methods: {
    pwd() {
      this.type = "pwd";
    },
    findings() {
      this.type = "find";
    },
    notificationpreference() {
      this.type = "noti";
    },
  },
};
</script>
<style scoped>
li {
  cursor: pointer;
}
</style>
