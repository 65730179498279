validation-listener
<template>
  <div>
    <div class="modal-header">
      {{ title }}
    </div>
    <div class="modal-content scroll">
      <ValidationListener
        @getIsValid="(isValid) => (findings.isFormValid = isValid)"
      >
        <!-- <div class="form-vertical m-0">
          <label>Eye Section*</label>
          <check-box-input
            :options="[{ value: 'Both', text: 'OU' }]"
            v-model="findings.postData.eyeSection"
          />
        </div> -->
        <div v-if="findings.type == 'Additional'" class="form-vertical">
          <label>Group*</label>
          <span v-if="findings.selectedGroup">{{
            findings.selectedGroup.groupName
          }}</span>
          <ValidationHandler rules="required" v-slot="{ error }" v-else>
            <select-input
              name="Select Group Name"
              displayName="Select Group Name"
              placeHolder="Select Group Name"
              :options="
                findings.groupList.map((x) => ({
                  value: x.groupID,
                  text: x.groupName,
                }))
              "
              v-model="findings.postData.groupID"
              :required="true"
              :error="error"
            />
          </ValidationHandler>
        </div>
        <div class="form-vertical">
          <label>Add Findings</label>
          <span v-if="findings.selectedGroup">{{
            this.currentlyselectedfinding.value.findingsName
          }}</span>
          <ValidationHandler rules="required" v-slot="{ error }" v-else>
            <text-input
              label="Add Findings"
              placeholder="Add Finding here"
              :required="true"
              v-model="findings.postData.findingsName"
              :error="error || findings.errors.findingsName"
            />
          </ValidationHandler>
        </div>
        <div class="form-vertical">
          <label>Diagnosis</label>
          <textarea
            v-model="findings.postData.diagnosys"
            type="textarea"
            placeholder="Add Diagnosis here"
          ></textarea>
        </div>
        <div class="form-vertical">
          <label>Recommendation</label>
          <textarea
            v-model="findings.postData.recommendation"
            type="textarea"
            placeholder="Add recommendation here"
          ></textarea>
        </div>
      </ValidationListener>
    </div>
    <div class="modal-footer">
      <md-button @click="cancel()">Cancel</md-button>
      <md-button class="md-primary" @click="handleSave()">Save</md-button>
    </div>

    <!-- <div class="modal-footer">
      <md-button @click="cancel()">Cancel</md-button>
      <div class="outline-blue-button">
        <md-button class="md-primary" @click="handleSave()">Save</md-button>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  inject: [
    "findingsMaster",
    "findings",
    "handleSave",
    "currentlyselectedfinding",
  ],
  data() {
    return {
      title: "",
    };
  },
  methods: {
    cancel() {
      this.findingsMaster.showManageFindings = false;
    },
  },
  mounted() {
    this.findings.postData.eyeSection = "Both";
    if (this.findingsMaster.masterName == "ExternalPhoto") {
      this.title = "EXTERNAL PHOTO";
    } else if (this.findingsMaster.masterName == "CornealTopography") {
      this.title = "CORNEAL TOPOGRAPHY";
    } else if (this.findingsMaster.masterName == "AnteriorSegmentOCT") {
      this.title = "ANTERIOR SEGMENT OCT";
    } else if (this.findingsMaster.masterName == "FundusPhoto") {
      this.title = "FUNDUS PHOTO";
    } else if (this.findingsMaster.masterName == "MacularOCT") {
      this.title = "MACULAR OCT";
    } else if (this.findingsMaster.masterName == "LensStatus") {
      this.title = "LENS STATUS";
    } else {
      this.title = "OPTIC NERVE OCT";
    }
  },
};
</script>
