<template>
  <div>
    <md-card>
      <md-card-header class="profile-header">
        <div class="card-icon">
          <i class="fas fa-2x far fa-cogs" />
        </div>
        <h4 class="title">My Notification</h4>
      </md-card-header>
      <md-card-content>
        <loading :active="app.showLoader.value" :can-cancel="true"></loading>
        <div class="profile-container">
          <div class="check-box-layout">
            <check-box-input
              name="allowemail"
              label="Allow Email notification"
              :options="[{ value: 1, text: null }]"
              v-model="
                NotificationPerferenceReset.postData.allowEmailNotification
              "
            />
          </div>
          <div class="check-box-layout">
            <check-box-input
              name="allowemail"
              label="Allow Popup notification"
              :options="[{ value: 1, text: null }]"
              v-model="
                NotificationPerferenceReset.postData.allowPopUpNotification
              "
            />
          </div>
          <div class="button-container">
            <md-button
              slot="footer"
              @click="NotificationPerferenceResetConfirm"
              class="md-primary"
            >
              Save
            </md-button>
            <md-button slot="footer"> Cancel </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import CheckBoxInput from "../../components/controls/CheckBoxInput.vue";

export default {
  inject: [
    "Permissions",
    "NotificationPerferenceResetConfirm",
    "NotificationPerferenceReset",
    "app",
    "progress",
  ],

  components: {
    CheckBoxInput,
  },
  methods: {
    resetpwd() {
      this.$router.push("/globe-chek/mysettings/resetpwd");
    },
  },
};
</script>
