<template>
  <fragment>
    <div class="card-sub-card">
      <h5>Additional Findings</h5>
      <div class="card-sub-card-contents">
        <spinner
          slot="footer"
          style="margin-bottom: 10px"
          :diameter="25"
          v-if="progress.isFindingsLoading"
        ></spinner>
        <div class="scroll">
          <div v-for="option in additionalFindings.list" :key="option.groupID">
            <div v-if="isVisible(option)">
              <b>{{ option.groupName }}</b>
              <div
                v-for="findings in option.findings.filter(
                  (x) => x.eyeSection == 'Both'
                )"
                :key="'additional-findings-' + findings.findingsID"
              >
                <ul>
                  <li
                    :style="getItemStyle(findings)"
                    @click="handleClickItem(findings)"
                  >
                    <div style="display: flex; width: 100%">
                      <div style="flex-grow: 8">
                        <span>{{ findings.findingsName }}</span>
                      </div>
                      <div style="flex-grow: 1">
                        <span>
                          <md-icon
                            @click.native="
                              handleManageFindingsClick(
                                'Additional',
                                findings.findingsID
                              )
                            "
                            >edit</md-icon
                          >
                        </span>
                      </div>
                      <div style="flex-grow: 1">
                        <span>
                          <md-icon
                            @click.native="
                              handleDeleteFindingsClick(findings.findingsID)
                            "
                            >delete</md-icon
                          >
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
export default {
  inject: [
    "additionalFindings",
    "handleManageFindingsClick",
    "handleDeleteFindingsClick",
    "currentlyselectedfinding",
    "progress",
    "isMyFindings",
  ],
  methods: {
    handleClickItem(findings) {
      this.currentlyselectedfinding.value = findings;
    },
    getItemStyle(findings) {
      let style = "border:0px;";
      if (this.currentlyselectedfinding.value.findingsID == findings.findingsID)
        style += "color:#3c6fe7";
      return style;
    },
    isVisible(option) {
      var findings = option.findings.filter((x) => x.eyeSection == "Both");
      return findings.length > 0;
    },
  },
};
</script>
<style scoped>
h5 {
  font-weight: bold;
}
</style>
