<template>
  <div class="modal-size-medium">
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>

    <FindingsFormSettings></FindingsFormSettings>
  </div>
</template>
<script>
import FindingsFormSettings from "./FindingFormSettings.vue";
export default {
  components: { FindingsFormSettings },
  inject: ["app"],
};
</script>
