<template>
  <md-card>
    <md-card-header class="profile-header">
      <h4 class="title">Screening Evaluation</h4>
    </md-card-header>
    <md-card-content>
      <findingsHandler masterName="ExternalPhoto">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="CornealTopography">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="AnteriorSegmentOCT">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="LensStatus">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="FundusPhoto">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="MacularOCT">
        <FindingsPreference />
      </findingsHandler>
      <findingsHandler masterName="OpticNerveOCT">
        <FindingsPreference />
      </findingsHandler>
    </md-card-content>
  </md-card>
</template>
<script>
import findingsHandler from "../../handlers/module-handlers/findings/findingsHandler";
import FindingsPreference from "@/components/Globechek/Findings/FindingsPreference.vue";
export default {
  components: { findingsHandler, FindingsPreference },
  inject: ["progress", "app"],
};
</script>
