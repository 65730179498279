var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationListener',{on:{"getIsValid":function (isValid) { return (_vm.passwordReset.isFormValid = isValid); }}},[_c('md-card',[_c('md-card-header',{staticClass:"profile-header"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"fas far fa-key"})]),_c('h4',{staticClass:"title"},[_vm._v("PASSWORD RESET")])]),_c('md-card-content',[_c('loading',{attrs:{"active":_vm.app.showLoader.value,"can-cancel":true}}),_c('div',{staticClass:"md-layout"},[(_vm.app.userProfile.isTempPassword)?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(" You are using a temporary password. Please change your password to continue. ")])]):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100 p-4"},[_c('div',{attrs:{"slot":"inputs"},slot:"inputs"},[_c('ValidationHandler',{attrs:{"name":_vm.passwordReset.postData.email,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('text-input',{attrs:{"type":"email","label":"Email","placeholder":"Email","icon":"mail","required":true,"error":error,"isdisabled":true,"icons":"mail_outline"},on:{"submit":_vm.passwordResetConfirm1},model:{value:(_vm.passwordReset.postData.email),callback:function ($$v) {_vm.$set(_vm.passwordReset.postData, "email", $$v)},expression:"passwordReset.postData.email"}})]}}])}),_c('ValidationHandler',{attrs:{"name":_vm.passwordReset.postData.currentPassword,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('text-input',{attrs:{"type":"password","label":"Current Password","placeholder":"Current Password","icon":"lock_outline","required":true,"error":error,"icons":"password"},on:{"submit":_vm.passwordResetConfirm1},model:{value:(_vm.passwordReset.postData.currentPassword),callback:function ($$v) {_vm.$set(_vm.passwordReset.postData, "currentPassword", $$v)},expression:"passwordReset.postData.currentPassword"}})]}}])}),_c('ValidationHandler',{attrs:{"name":_vm.passwordReset.postData.password,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('text-input',{attrs:{"type":"password","label":"New Password","placeholder":"New Password","icon":"lock_outline","required":true,"error":error,"icons":"password"},on:{"submit":_vm.passwordResetConfirm1},model:{value:(_vm.passwordReset.postData.password),callback:function ($$v) {_vm.$set(_vm.passwordReset.postData, "password", $$v)},expression:"passwordReset.postData.password"}})]}}])}),_c('ValidationHandler',{attrs:{"name":_vm.passwordReset.postData.confirmPassword,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('text-input',{attrs:{"type":"password","label":"Confirm Password","placeholder":"Confirm Password","icon":"lock_outline","required":true,"error":error,"icons":"password"},on:{"submit":_vm.passwordResetConfirm1},model:{value:(_vm.passwordReset.postData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordReset.postData, "confirmPassword", $$v)},expression:"passwordReset.postData.confirmPassword"}})]}}])})],1),_c('div',[_c('div',{staticClass:"button-container"},[_c('md-button',{staticClass:"md-primary",attrs:{"slot":"footer"},on:{"click":_vm.passwordResetConfirm1},slot:"footer"},[_vm._v(" Reset ")]),_c('md-button',{attrs:{"slot":"footer"},on:{"click":_vm.cancelReset},slot:"footer"},[_vm._v("Cancel")])],1)])]),_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100 p-4"},[_c('h3',{staticClass:"md-subheading"},[_vm._v("Note: Password Criteria")]),_c('ul',[_c('li',[_vm._v("Password must be at least 10 characters long")]),_c('li',[_vm._v("Password must be at most 50 characters long")]),_c('li',[_vm._v(" Password must have at least one alphabet, one numerical, and one special character (@,$,!,%,*,#,?,&,_,-). ")]),_c('li',[_vm._v("Username and Password must not be the same")])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }