<template>
  <fragment>
    <div class="card-sub-card">
      <h5>Common Findings</h5>
      <div class="card-sub-card-contents">
        <spinner
          slot="footer"
          style="margin-bottom: 10px"
          :diameter="25"
          v-if="progress.isFindingsLoading"
        ></spinner>
        <div class="scroll">
          <ul>
            <li
              v-for="option in commonFindings.list"
              v-bind:key="option.findingsID"
              :style="getItemStyle(option)"
              @click="handleClickItem(option)"
            >
              <div style="display: flex; width: 100%">
                <div style="flex-grow: 8">
                  <span>{{ option.findingsName }}</span>
                </div>
                <div style="flex-grow: 1">
                  <span>
                    <md-icon
                      @click.native="
                        handleManageFindingsClick('Common', option.findingsID)
                      "
                      >edit</md-icon
                    >
                  </span>
                </div>
                <div style="flex-grow: 1" v-if="isSuperAdmin()">
                  <span>
                    <md-icon
                      @click.native="
                        handleDeleteFindingsClick(option.findingsID)
                      "
                      >delete</md-icon
                    >
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
export default {
  inject: [
    "commonFindings",
    "handleManageFindingsClick",
    "handleDeleteFindingsClick",
    "currentlyselectedfinding",
    "progress",
    "Permissions",
    "isSuperAdmin",
  ],
  methods: {
    handleClickItem(findings) {
      this.currentlyselectedfinding.value = findings;
    },
    getItemStyle(findings) {
      let style = "border:0px;";
      if (this.currentlyselectedfinding.value.findingsID == findings.findingsID)
        style += "color:#3c6fe7";
      return style;
    },
  },
};
</script>
<style scoped>
h5 {
  font-weight: bold;
}
</style>
